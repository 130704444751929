<template>
  <section class="body-sign">
    <div class="center-sign">
      <a href="/" class="logo float-left mt-3">
        <img src="/img/logo-short-color.png" height="40" alt="Estoque Auto Logo" />
      </a>
      <div class="panel card-sign">
        <div class="card-title-sign mt-3 text-right">
          <h2 class="title text-uppercase font-weight-bold m-0"><i class="fas fa-user mr-1"></i> Cadastro grátis</h2>
        </div>
        <div class="card-body">
          <h4 class="mt-0 mb-4 font-weight-bold">
            2. Endereço<br />
            <small class="text-muted">Informe seu endereço, ou de sua empresa.</small>
          </h4>
          <form autocomplete="off" action="/auth/login" id="form" method="post">
            <input autocomplete="false" name="hidden" type="text" style="display:none;">
            <div class="form-group mb-3">
              <label>CEP</label>
              <div class="input-group">
                <input @change="buscaCEP(userData.CEP, cepCallback)"
                       type="tel"
                       v-model="userData.CEP"
                       class="form-control form-control-lg"
                       v-mask="'#####-###'"
                       @focus="removeError('CEP')"/>
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-address-book"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{ errors['CEP'] }}</span>

            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Bairro</label>
              </div>
              <div class="input-group">
                <input class="form-control form-control-lg" v-model="userData.Bairro" type="text" @change="removeError('Bairro')" @focus="removeError('Bairro')"/>
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-map"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{ errors['Bairro'] }}</span>
            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Logradouro</label>
              </div>
              <div class="input-group">
                <input class="form-control form-control-lg" v-model="userData.Logradouro" type="text" @change="removeError('Logradouro')" @focus="removeError('Logradouro')"/>
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-road"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{ errors['Logradouro'] }}</span>
            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Número</label>
              </div>
              <div class="input-group">
                <input class="form-control form-control-lg" maxlength="9" v-model="userData.Numero" type="text" @focus="removeError('Numero')"/>
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-list-ol"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{ errors['Numero'] }}</span>

            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Complemento</label>
              </div>
              <div class="input-group">
                <input class="form-control form-control-lg" v-model="userData.Complemento" type="text" />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-building"></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Estado</label>
              </div>
              <div class="input-group">

                <select class="form-control form-control-lg" v-model="userData.Estado" @change="consultaCidade">
                  <option :value="e.Id" :key="e.Id" v-for="e in estados">
                    {{e.Descricao}}
                  </option>
                </select>
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-flag"></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Cidade</label>
              </div>
              <div class="input-group">
                <select class="form-control form-control-lg" v-model="userData.CidadeId"  @focus="removeError('CidadeId')">
                  <option :value="c.Id" :key="c.Id" v-for="c in cidades">
                    {{c.Descricao}}
                  </option>
                </select>
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-map-marker-alt"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{ errors['CidadeId'] }}</span>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button type="button" @click="anterior()" class="btn btn-default mt-2 float-left" id="entrar" :disabled="loading">
                  {{loading ? 'Autenticando' : 'Voltar'}}
                </button>
                <button type="button" @click="proximo()" class="btn btn-primary mt-2 float-right" id="entrar" :disabled="loading">
                  {{loading ? 'Autenticando' : 'Próximo'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p class="text-center text-muted mt-3 mb-3">&copy; Estoque Auto. Todos os direitos reservados.</p>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import api from '@/services/external.api.js';
import {getCurrentUser,setCurrentUser,userEmpty } from '@/services/external.helpers.js';

export default {
    data() {
        return {
            userData:userEmpty(),
            estados: [],
            cidades: [],
            errors: {},
            loading: false,
          };
    },
    created() {
      let that = this;
      api.get('/external/estados')
        .then(response => {
          that.estados = response.data;
          that.$nextTick(function () {
            that.userData = getCurrentUser();
            if (that.userData.Estado) {
              that.consultaCidade();
            }
          });
        });
        
    },
    methods: {
      buscaCEP: window.buscaCEP,
      cepCallback: function (dados) {
          if (!("erro" in dados)) {
            //Atualiza os campos com os valores da consulta.
            let estado = this.estados.filter(e => e.Sigla === dados.uf)[0];
            document.novaCidade = dados.localidade;
            
            this.userData.Bairro = dados.bairro;
            this.userData.Logradouro = dados.logradouro;
            this.userData.Estado = estado.Id;
            this.consultaCidade();

          }
        },
      consultaCidade: function () {
        let estado = this.userData.Estado;
        let that = this;
        if (estado) {
            api
                .get('/external/cidades?id=' + estado)
                .then(response => {
                    if (response.data && response.data.length) {
                        that.cidades = response.data;
                        if (document.novaCidade) {
                            var cidade = that.cidades.filter(e => e.Descricao === document.novaCidade)[0];
                            if (cidade) {
                              that.userData.Cidade = cidade;
                              that.userData.CidadeId = cidade.Id;
                            }
                            document.novaCidade = null;
                        }
                    } else {
                        that.cidades = [];
                    }
                });

        } else {
            this.cidades = [];
        }

      },
      validar() {
    //     CEP: '',
    //Bairro: '',
    //Logradouro: '',
    //Numero: '',
    //Complemento: '',
    //Cidade: '',
    //CidadeId: '',
    //CEP: '',
    //Estado: null,

        this.errors = {};

        if (this.userData.CEP.length < 8) {
          this.errors['CEP'] = 'CEP inválido.';
        }
        if (this.userData.Bairro.length < 2) {
          this.errors['Bairro'] = 'Bairro inválido.';
        }
        if (this.userData.Logradouro.length < 2) {
          this.errors['Logradouro'] = 'Logradouro inválido.';
        }
        if (this.userData.Numero.length === 0) {
          this.errors['Numero'] = 'Numero inválido.';
        }
        if (!this.userData.CidadeId) {
          this.errors['CidadeId'] = 'Cidade inválida.';
        }

        return Object.keys(this.errors).length === 0;
      },
      removeError(prop) {
        this.errors[prop] = '';
        delete this.errors[prop];

        this.$nextTick(() => {
          let obj = new Object();
          for (var i in this.errors) {
            obj[i] = this.errors[i];
          }
          this.errors = obj;
        });
      },
      proximo: function () {
        if (this.validar()) {
          setCurrentUser(this.userData);
          this.$router.push('/cadastro/contato');            
        }else {
          window.scrollTo(0,0);
        }
        
      },
      anterior: function () {
        setCurrentUser(this.userData);
        this.$router.push('/cadastro/dados');                    
      },
    },

}

</script>
